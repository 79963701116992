export function downloadLink (href, fileName) {
  const a = document.createElement('a')
  a.href = href
  a.style.display = 'none'

  if (fileName) {
    a.download = fileName
  }

  document.body.appendChild(a)
  a.click()
  a.remove()
}
