export const mixinInterval = {
  data: () => ({
    intervalId: null
  }),
  methods: {
    setIntervalId (handler, timeout) {
      this.intervalId = setInterval(handler, timeout)
    },
    clearIntervalId () {
      clearInterval(this.intervalId)
    }
  }
}
