<template>
  <div class="docx-viewer">
    <Spinner v-if="isLoading"/>

    <div ref="docxWrapper"/>
  </div>
</template>

<script>
import { mixinFile } from '@/mixins/files/mixinFile'
import { mixinIsLoading } from '@/mixins/mixinIsLoading'

import { renderAsync } from 'docx-preview'

import { setAlertError } from '@/utils/store/alert'

export default {
  name: 'DocxViewer',
  mixins: [mixinFile, mixinIsLoading],

  watch: {
    file: 'renderDocx'
  },

  mounted () {
    this.renderDocx()
  },

  methods: {
    async renderDocx () {
      try {
        this.clearWrapper()
        this.startLoading()
        await renderAsync(this.file, this.$refs.docxWrapper)
      } catch (e) {
        await setAlertError(e, 'Не удалось загрузить файл')
      } finally {
        this.finishLoading()
      }
    },
    clearWrapper () {
      this.$refs.docxWrapper.innerHTML = ''
    }
  }
}
</script>

<style lang="scss">
  .docx-viewer {
    .docx-wrapper {
      background-color: transparent;
    }
  }
</style>
