<template>
  <div
    class="scrolling-document"
    v-scroll.immediate="updateScrollBounds"
  >
    <ScrollingPage
      v-for="item of pages"
      :key="item.id"
      v-bind="{ page: item.page, clientHeight, scrollTop, focusedPage, enablePageJump }"
      @page-jump="onPageJump"
    >
      <template v-slot:default="{ isPageFocused, isElementFocused }">
        <slot v-bind="{ page: item.page, isPageFocused, isElementFocused }"/>
      </template>
    </ScrollingPage>

    <div v-visible="fetchPages" class="observer"/>
  </div>
</template>

<script>
import ScrollingPage from '@/components/files/ScrollingDocument/ScrollingPage'

import scroll from '@/directives/scroll'
import visible from '@/directives/visible'

export default {
  name: 'ScrollingDocument',
  components: {
    ScrollingPage
  },
  directives: {
    visible,
    scroll
  },
  props: {
    pages: {
      type: Array,
      required: true
    },
    enablePageJump: {
      type: Boolean,
      default: false
    },
    currentPage: {
      type: Number,
      default: 1
    },
    isParentVisible: {
      type: Boolean,
      default: true
    }
  },

  data: () => ({
    focusedPage: null,
    scrollTop: 0,
    clientHeight: 0
  }),

  computed: {
    countPagesLoaded () {
      return this.pages.length
    }
  },

  methods: {
    fetchPages (pageNumber) {
      this.$emit('pages-fetch', pageNumber)
    },

    onPageJump (scrollTop) {
      this.$emit('page-jump', scrollTop)
    },

    updateScrollBounds () {
      const { scrollTop, clientHeight } = this.$el
      this.scrollTop = scrollTop
      this.clientHeight = clientHeight
    }
  },

  watch: {
    isParentVisible: 'updateScrollBounds',

    countPagesLoaded (_, oldCount) {
      if (oldCount === 0) this.$emit('pages-reset')

      this.$nextTick(() => {
        this.focusedPage = this.currentPage
      })
    },

    currentPage (currentPage) {
      if (currentPage > this.countPagesLoaded) {
        this.fetchPages(currentPage)
      } else {
        this.focusedPage = currentPage
      }
    }
  }
}
</script>
