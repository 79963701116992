<template>
  <div class="pdf-viewer custom-scroll">
    <PDFData
      :url="url"
      @update-count-pages="setCountPages"
      @page-focus="setCurrentPage"
    >
<!--      @reset-current-page=""-->
      <template v-slot:document="{ pages }">
        <PDFDocument
          :pages="pages"
          :scale="scale"
          :optimal-scale="optimalScale"
          :fit="fit"
          :current-page="currentPage"
          :page-count="countPages"
          :is-preview-enabled="isPreviewEnabled"
          @update-scale="setScale"
          @click.native.stop
        />
      </template>
    </PDFData>

    <div
      v-show="countPages"
      class="pdf-viewer__controls flex gap-xs"
      @click.stop
    >
      <DocumentPagination
        class="pdf-viewer__pagination color--white"
        :current-page="currentPage"
        :count-pages="countPages"
      />

      <DocumentZoom
        class="pdf-viewer__zoom"
        :scale="scale"
        :fit="fit"
        @update-scale="setScale"
        @update-fit="setFit"
      />
    </div>
  </div>
</template>

<script>
import DocumentPagination from '@/components/files/Document/DocumentPagination'
import PDFData from '@/components/files/PDF/PDFData'
import PDFDocument from '@/components/files/PDF/PDFDocument'
import DocumentZoom from '@/components/files/Document/DocumentZoom'

function floor (value, precision) {
  const multiplier = Math.pow(10, precision || 0)
  return Math.floor(value * multiplier) / multiplier
}

export default {
  name: 'PDFViewer',
  components: { DocumentZoom, DocumentPagination, PDFData, PDFDocument },
  props: {
    url: {
      type: String,
      required: true
    }
  },
  data: () => ({
    scale: 2,
    optimalScale: 0,
    fit: '',
    currentPage: 1,
    countPages: 0,
    isPreviewEnabled: false
  }),

  methods: {
    setCountPages (num) {
      this.countPages = num
    },

    setCurrentPage (pageNumber) {
      this.currentPage = pageNumber
    },

    setScale ({ scale, isOptimal = false }) {
      const roundedScale = floor(scale, 2)
      if (isOptimal) this.optimalScale = roundedScale
      this.scale = roundedScale
    },

    setFit (fit) {
      this.fit = fit
    },

    togglePreview () {
      this.isPreviewEnabled = !this.isPreviewEnabled
    }
  }
}
</script>

<style lang="scss">
.pdf-viewer {
  @include absoluteFull;

  &__controls {
    position: fixed;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 2;
    border-radius: var(--border-radius);
    padding: var(--xxs) var(--xs);
    background-color: transparentize($color__font--dark, .3);
  }
}
</style>
