<template>
  <button
    class="btn-reset"
    type="button"
  >
    <svgicon
      icon="button/swiper-arrow"
      width="9"
      height="17"
    />
  </button>
</template>

<script>
export default {
  name: 'ButtonSwiperArrow'
}
</script>
