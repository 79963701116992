<template>
  <XlsxRead
    class="xlsx-viewer"
    :file="file2xlsx"
    @parsed="parsedXlsx"
  >
    <template v-slot:default="{ loading }">
      <VSelect
        v-if="!loading && visibleSelect"
        v-model="selectedSheet"
        class="max-w-input mb-20"
        :options="sheets"
        :allow-empty="false"
      />

      <XlsxTable
        v-if="!loading"
        ref="xlsxTable"
        class="xlsx-viewer__table border--main bg--white custom-scroll"
        :sheet="currentSheet"
      />

      <Spinner v-if="loading" />
    </template>
  </XlsxRead>
</template>

<script>
import { XlsxRead, XlsxTable } from 'vue-xlsx'
import Spinner from '@/components/ui/Spinner'
import VSelect from '@/components/Form/VSelect'

import { mixinFile } from '@/mixins/files/mixinFile'
import { isFilePrototype } from '@/utils/files'
import { refreshArr } from '@/utils/array/reactive'

export default {
  name: 'XlsxViewer',
  components: {
    Spinner,
    VSelect,
    XlsxRead,
    XlsxTable
  },
  mixins: [mixinFile],

  data: () => ({
    sheets: [],
    selectedSheet: null
  }),

  computed: {
    file2xlsx () {
      if (isFilePrototype(this.file)) {
        return this.file
      }
      return null
    },

    mapped () {
      return sheets => sheets.map((item, i) => ({
        name: item,
        idx: i
      }))
    },

    currentSheet () {
      return this.selectedSheet?.idx || 0
    },

    visibleSelect () {
      return this.sheets.length
    }
  },

  methods: {
    parsedXlsx (payload) {
      this.setSheets(payload.SheetNames)
    },

    setSheets (sheets) {
      if (!sheets?.length) return

      const mapped = this.mapped(sheets)
      refreshArr(this.sheets, mapped)

      this.selectedSheet = this.sheets[0]
    }
  }
}
</script>

<style lang="scss">
.xlsx-viewer {
  width: 100%;

  &__table {
    width: 100%;
    overflow-x: scroll;

    table {
      border-collapse: collapse;

      td {
        border: 1px solid var(--color__gray-blue);
        padding: 10px 5px;
        min-width: initial;
        text-align: initial;
        vertical-align: initial;
      }
    }
  }
}
</style>
