<template>
  <canvas
    class="pdf-page"
    v-bind="canvasAttrs"
    v-visible.once="renderPage"
  />
</template>

<script>
import visible from '@/directives/visible'
import { PIXEL_RATIO } from '@/const/viewport'
import { setAlertError } from '@/utils/store/alert'

export default {
  name: 'PDFPage',
  directives: {
    visible
  },
  props: {
    page: {
      type: Object,
      required: true
    },
    scale: {
      type: Number,
      required: true
    },
    optimalScale: {
      type: Number,
      required: true
    },
    isPageFocused: {
      type: Boolean,
      default: false
    },
    isElementFocused: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    canvasAttrs () {
      let { width, height } = this.viewport;
      [width, height] = [width, height].map(dim => Math.ceil(dim))

      return {
        width,
        height,
        style: this.canvasStyle
      }
    },

    canvasStyle () {
      const { width: actualSizeWidth, height: actualSizeHeight } = this.actualSizeViewport

      const [width, height] = [actualSizeWidth, actualSizeHeight].map(dim => Math.ceil(dim / PIXEL_RATIO))

      return `width: ${width}px; height: ${height}px;`
    },

    actualSizeViewport () {
      return this.viewport.clone({ scale: this.scale })
    },

    pageNumber () {
      return this.page.pageNumber
    }
  },

  watch: {
    page (_, oldPage) {
      this.destroyPage(oldPage)
    },

    isElementFocused (bool) {
      if (!bool) return

      this.focusPage()
    },

    scale: 'updateVisibility'
  },

  created () {
    this.viewport = this.page.getViewport({ scale: this.optimalScale })
  },

  beforeDestroy () {
    this.destroyPage(this.page)
  },

  methods: {
    focusPage () {
      if (this.isPageFocused) return

      this.$emit('page-focus', this.pageNumber)
    },

    async renderPage () {
      if (this.renderTask) return

      try {
        const renderContext = this.getRenderContext()

        await this.page.render(renderContext)
        this.$emit('page-rendered', this.page)
      } catch (e) {
        this.destroyRenderTask()
        await setAlertError(e, `Не удалось отрисовать страницу №${this.pageNumber}`)
      }
    },

    getRenderContext () {
      const canvasContext = this.$el.getContext('2d')

      return {
        canvasContext,
        viewport: this.viewport
      }
    },

    updateVisibility () {
      this.$parent.$emit('update-visibility')
    },

    destroyPage (page) {
      if (page) page._destroy()

      this.destroyRenderTask()
    },

    destroyRenderTask () {
      if (!this.renderTask) return

      this.renderTask.cancel()
      delete this.renderTask
    }
  }
}
</script>

<style>
</style>
