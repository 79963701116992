/* eslint-disable no-undef */
import { setAlertError } from '@/utils/store/alert'
import { getRange } from '@/utils/array'

export async function fetchPDF (url) {
  try {
    // pdfjsLib подключена через cdn
    const loadingTask = pdfjsLib.getDocument(url)
    return await loadingTask.promise
  } catch (e) {
    await setAlertError(e, 'Не удалось открыть файл PDF')
  }
}

export async function fetchPages (pdf, start, end) {
  try {
    const count = (end - start) + 1
    const range = getRange(count, start)

    return await Promise.all(range.map(number => pdf.getPage(number)))
  } catch (e) {
    await setAlertError(e, 'Не удалось загрузить страницы PDF документа')
  }
}
