<template>
  <ScrollingDocument
    class="pdf-document gap-s custom-scroll"
    :pages="pages"
    :current-page="currentPage"
    enable-page-jump
    @page-jump="onPageJump"
    @pages-fetch="onPagesFetch"
    @pages-reset="fitWidth"
  >
    <template v-slot:default="{ page, isPageFocused, isElementFocused }">
      <PDFPage
        :page="page"
        :scale="scale"
        :optimal-scale="optimalScale"
        :is-page-focused="isPageFocused"
        :is-element-focused="isElementFocused"
        @page-focus="onPageFocused"
      />
    </template>
  </ScrollingDocument>
</template>

<script>
import PDFPage from '@/components/files/PDF/PDFPage'
import ScrollingDocument from '@/components/files/ScrollingDocument/ScrollingDocument'

import { PIXEL_RATIO, VIEWPORT_RATIO } from '@/const/viewport'

export default {
  name: 'PDFDocument',
  components: {
    PDFPage,
    ScrollingDocument
  },

  props: {
    pages: {
      type: Array,
      required: true
    },
    pageCount: {
      type: Number,
      default: 0
    },
    scale: {
      type: Number,
      default: 2
    },
    optimalScale: {
      type: Number
    },
    fit: {
      type: String
    },
    currentPage: {
      type: Number,
      default: 1
    },
    isPreviewEnabled: {
      default: false
    }
  },

  computed: {
    isPortrait () {
      return this.currentViewport.width <= this.currentViewport.height
    },

    currentViewport () {
      if (!this.pages.length) return { width: 0, height: 0 }

      const page = this.pages[0].page
      return page.getViewport({ scale: this.scale })
    }
  },

  watch: {
    fit (fit) {
      switch (fit) {
        case 'width':
          this.fitWidth()
          break

        case 'auto':
          this.fitAuto()
      }
    },
    pageCount: 'fitWidth',
    isPreviewEnabled: 'fitWidth'
  },

  methods: {
    onPageJump (scrollTop) {
      this.$el.scrollTop = scrollTop
    },

    onPagesFetch (pageNumber) {
      this.$parent.$emit('pages-fetch', pageNumber)
    },

    onPageFocused (pageNumber) {
      this.$parent.$emit('page-focus', pageNumber)
    },

    fitWidth () {
      this.updateScale(this.getPageWidthScale(), !this.optimalScale)
    },

    fitHeight () {
      const scale = this.isPortrait ? this.getPageHeightScale() : this.getPageWidthScale()
      this.updateScale(scale)
    },

    fitAuto () {
      const scale = Math.min(this.getPageWidthScale(), this.getPageHeightScale())
      this.updateScale(scale)
    },

    getPageWidthScale () {
      if (!this.currentViewport.width) return 0
      return this.getScaleBy(this.$el.clientWidth, this.currentViewport.width)
    },

    getPageHeightScale () {
      if (!this.currentViewport.height) return 0

      return this.getScaleBy(this.$el.clientHeight, this.currentViewport.height)
    },

    getScaleBy (value, currentViewportValue) {
      return (value * PIXEL_RATIO * VIEWPORT_RATIO) / currentViewportValue
    },

    updateScale (scale, isOptimal = false) {
      if (!scale) return
      this.$emit('update-scale', { scale, isOptimal })
    }
  }
}
</script>

<style lang="scss">
.pdf-document {
  @include absoluteFull;
  overflow: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px 0;
}
</style>
