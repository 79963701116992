<template>
  <div class="document-pagination flex">
    Страница {{ currentPage }} из {{ countPages }}
  </div>
</template>

<script>
export default {
  name: 'DocumentPagination',
  props: {
    currentPage: {
      type: Number,
      required: true
    },
    countPages: {
      type: Number,
      required: true
    }
  }
}
</script>

<style>

</style>
