import printJS from 'print-js'

export function printFile (src, type = 'pdf') {
  return printJS(src, type)
}

export function printImages (sources) {
  return printJS({
    printable: sources,
    type: 'image'
  })
}
