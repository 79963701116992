<template>
  <div class="pdf-data">
    <slot name="preview" :pages="pages" />

    <slot name="document" :pages="pages" />

    <Spinner v-if="isLoading" />
  </div>
</template>

<script>
import { fetchPages, fetchPDF } from '@/utils/pdfjsLib'
import { mixinIsLoading } from '@/mixins/mixinIsLoading'
import { generateId } from '@/utils/create'

const BATCH_PAGES = 10

export default {
  name: 'PDFData',
  props: {
    url: {
      type: String,
      required: true
    }
  },

  mixins: [mixinIsLoading],

  data: () => ({
    pages: [],
    cursor: 0,
    pdf: null
  }),

  computed: {
    countPages () {
      return this.pdf?.numPages || 0
    },

    countPagesLoaded () {
      return this.pages.length
    }
  },

  watch: {
    url: {
      async handler (url) {
        if (!url) return

        this.pdf = await this.fetchPDF(url)
      },
      immediate: true
    },

    pdf (_, oldPdf) {
      if (oldPdf) {
        this.resetData()
      }

      this.updateCountPages()
      this.fetchPages()
    }
  },

  created () {
    this.$on('pages-fetch', this.fetchPages)
  },

  methods: {
    async fetchPDF (url) {
      try {
        this.startLoading()
        return await fetchPDF(url)
      } finally {
        this.finishLoading()
      }
    },

    async fetchPages (requestedPage = 1) {
      if (!this.checkPagesLoadingAbility()) return

      const { startPage, endPage } = this.getStartEndPages(requestedPage)

      this.cursor = endPage

      try {
        this.startLoading()

        const pages = await fetchPages(this.pdf, startPage, endPage)

        const mappedPages = pages.map(item => ({
          page: item,
          id: generateId()
        }))

        this.pages.splice(this.countPagesLoaded, 0, ...mappedPages)
      } finally {
        this.finishLoading()
      }
    },

    getStartEndPages (requestedPage) {
      const startPage = this.countPagesLoaded + 1

      const requiredLastPage = Math.max(requestedPage, (this.countPagesLoaded + BATCH_PAGES))

      const endPage = Math.min(requiredLastPage, this.countPages)

      return {
        startPage,
        endPage
      }
    },

    checkPagesLoadingAbility () {
      if (!this.pdf) return false

      if (this.countPages > 0 && (this.countPagesLoaded === this.countPages)) return false

      return this.cursor <= this.countPagesLoaded
    },

    updateCountPages () {
      this.$emit('update-count-pages', this.countPages)
    },

    resetData () {
      this.pages.length = 0
      this.cursor = 0
    }
  }
}
</script>
