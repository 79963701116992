<template>
  <div class="modal modal-preview custom-scroll" :class="activeClass">
    <header class="modal-preview__header">
      <div class="modal-preview__toolbar container between-center gap-m">
        <h4 class="color--white">{{ fileName }}</h4>

        <div class="flex gap-s">
          <transition name="fade">
            <a
              v-if="!isFilePrototype"
              class="modal-preview__link gap-xxs link-reset"
              :href="fileURL"
              @click.prevent="downloadFile"
            >
              <svgicon
                icon="button/export"
                width="18"
                height="18"
              />
              Скачать
            </a>
          </transition>

          <transition name="fade">
            <button
              v-if="!isDisabledPrint"
              class="modal-preview__button gap-xxs btn-reset"
              type="button"
              :disabled="isDisabledPrint"
              @click="printFile"
            >
              <svgicon
                icon="button/print"
                width="19"
                height="18"
              />
              Печать
            </button>
          </transition>

          <button
            class="modal-preview__button-close btn-cross btn-reset"
            type="button"
            @click="close"
          />
        </div>
      </div>
    </header>

    <OfficeViewer
      v-if="visibleOfficeViewer"
      class="modal-preview__office-viewer"
      :src="fileURL"
    />

    <PDFViewer
      v-else-if="fileType === 'pdf'"
      :url="fileURL"
    />

    <div v-else class="modal__wrap" @mousedown.self="close">
      <div class="modal-preview__content">
        <ImagePreview v-if="fileType === 'image'" :file="activeFile" />

        <XlsxViewer
          v-else-if="isFilePrototype && (fileType === 'xlsx')"
          ref="xlsxViewer"
          :file="activeFile"
        />

        <DocxViewer
          v-else-if="fileType === 'docx'"
          ref="docxViewer"
          :file="activeFile"
        />

        <div v-else class="p-m pt-xs pb-xs border--main bg--disabled">Предварительный просмотр недоступен</div>
      </div>
    </div>

    <ButtonSwiperArrow
      v-if="!isFirstFile"
      class="modal-preview__button-nav modal-preview__button-nav--prev modal-preview__button"
      @click.native="prevFile"
    />

    <ButtonSwiperArrow
      v-if="!isLastFile"
      class="modal-preview__button-nav modal-preview__button-nav--next modal-preview__button"
      @click.native="nextFile"
    />
  </div>
</template>

<script>
import ButtonSwiperArrow from '@/components/ui/button/ButtonSwiperArrow'
import DocxViewer from '@/components/files/DocxViewer/DocxViewer'
import ImagePreview from '@/components/files/ImagePreview/ImagePreview'
import PDFViewer from '@/components/files/PDF/PDFViewer'
import XlsxViewer from '@/components/files/XlsxViewer/XlsxViewer'
import { getFileName, getFileTypeBy, getURL, isFilePrototype } from '@/utils/files'
import { printFile, printImages } from '@/utils/print/printJS'
import { getHTML2canvasDataURL } from '@/utils/html2canvasLib'
import OfficeViewer from '@/components/files/OfficeViewer/OfficeViewer'
import { showAlertWarning } from '@/utils/store/alert'
import { downloadLink } from '@/shared/lib/browser/download-link'

export default {
  name: 'ModalFilesPreview',
  components: {
    OfficeViewer,
    ButtonSwiperArrow,
    DocxViewer,
    ImagePreview,
    PDFViewer,
    XlsxViewer
  },
  props: {
    files: {
      type: [Array, FileList],
      required: true
    },
    initActiveFileIdx: {
      type: Number,
      required: false,
      default: 0
    }
  },

  data: () => ({
    activeClass: '',
    activeFileIdx: null
  }),
  computed: {
    fileURL () {
      return getURL(this.activeFile)
    },

    isDisabledPrint () {
      if (this.visibleOfficeViewer && this.fileType === 'pdf') return false
      else if (this.visibleOfficeViewer) return true

      switch (this.fileType) {
        case 'pdf':
        case 'xlsx':
        case 'docx':
        case 'image': return false
        default: return true
      }
    },

    visibleOfficeViewer () {
      if (this.isFilePrototype) return false

      switch (this.fileType) {
        case 'pdf':
        case 'docx':
        case 'xlsx': return true
        default: return false
      }
    },

    isFilePrototype () {
      return isFilePrototype(this.activeFile)
    },

    fileType () {
      const type = getFileTypeBy(this.fileName)

      switch (type) {
        case 'pdf': return type
        case 'doc':
        case 'docx': return 'docx'
        case 'xls':
        case 'xlsx': return 'xlsx'
        case 'png':
        case 'webp':
        case 'gif':
        case 'jpg':
        case 'jpeg': return 'image'
        default: return ''
      }
    },

    fileName () {
      return getFileName(this.activeFile)
    },

    activeFile () {
      return this.files[this.activeFileIdx]
    },

    isFirstFile () {
      return this.activeFileIdx === 0
    },

    isLastFile () {
      return this.activeFileIdx === (this.countFiles - 1)
    },

    countFiles () {
      return this.files.length
    }
  },

  created () {
    this.setActiveFile(this.initActiveFileIdx)
  },
  mounted () {
    this.activeClass = 'modal--active'
  },

  destroyed () {
    this.activeClass = ''
  },

  methods: {
    close () {
      this.$emit('close')
    },

    async downloadFile () {
      let url = this.fileURL
      let name = this.fileName
      if (this.activeFile.url_docx) {
        url = this.activeFile.url_docx
        name = this.activeFile.url_docx.split('/').at(-1)
      }

      const response = await fetch(url, {
        mode: 'cors'
      })

      const blob = await response.blob()
      const blobURL = URL.createObjectURL(blob)

      downloadLink(blobURL, name)
    },

    printFile () {
      if (this.fileType === 'xlsx') {
        return this.printXlsx()
      }

      if (this.fileType === 'docx') {
        showAlertWarning('Началась подготовка документа для печати, пожалуйста, подождите')

        return setTimeout(this.printDocx, 0)
      }

      printFile(this.fileURL, this.fileType)
    },

    async printDocx () {
      const sources = await this.getDocxSources()
      if (!sources) return

      printImages(sources)
    },

    async getDocxSources () {
      try {
        const nodeList = this.$refs.docxViewer.$el.querySelectorAll('.docx')
        const pages = [...nodeList]

        return await Promise.all(pages.map(page => getHTML2canvasDataURL(page)))
      } catch (e) {
        await this.$store.dispatch(
          'alert/setAlertError',
          'Ошибка при подготовке документа для печати'
        )
      }
    },

    async printXlsx () {
      const src = await this.getXlsxSrc()
      if (!src) return

      printFile(src, 'image')
    },

    getXlsxSrc () {
      const element = this.$refs.xlsxViewer.$refs.xlsxTable.$el

      return getHTML2canvasDataURL(element)
    },

    prevFile () {
      this.activeFileIdx--
    },
    nextFile () {
      this.activeFileIdx++
    },

    setActiveFile (idx) {
      this.activeFileIdx = idx
    }
  }
}
</script>
