<template>
  <div>
    <Spinner v-if="loading" style="height: 100%;" />
    <iframe @load="onLoadIframe" width="100%" height="100%" :src="url" />
  </div>
</template>

<script>
import { mixinInterval } from '@/mixins/mixinInterval'
import { showAlertError } from '@/utils/store/alert'
export default {
  name: 'OfficeViewer',
  props: {
    src: {
      type: String,
      required: true
    }
  },
  mixins: [
    mixinInterval
  ],

  data () {
    return {
      loading: true,
      errorCount: 0,
      url: `https://docs.google.com/viewer?url=${this.src}&embedded=true`
    }
  },

  // docs.google не всегда прогружается с первого раза, поэтому пытаемся перезагрузить iframe
  mounted () {
    this.setIntervalId(() => {
      if (this.loading) {
        if (this.errorCount > 5) {
          showAlertError({ message: 'Не удалось загрузить документ в режиме просмотра!' })
          this.loading = false
          this.clearIntervalId()
          return
        }
        this.errorCount++
        this.url += ' '
      } else {
        this.clearIntervalId()
      }
    }, 4000)
  },

  destroyed () {
    this.clearIntervalId()
  },

  methods: {
    onLoadIframe () {
      this.loading = false
      this.clearIntervalId()
    }
  }
}
</script>
