<template>
  <div class="document-zoom flex">
    <button
      class="document-zoom__button pseudo-minus btn-reset"
      type="button"
      :disabled="isDisabledZoomOut"
      @click="zoomOut"
    />

    <button
      class="document-zoom__button-loupe document-zoom__button btn-reset"
      :class="buttonLoupeClasses"
      type="button"
      @click="switchZoom"
    >
      <svgicon icon="search" width="24px" height="24px" />
    </button>

    <button
      class="document-zoom__button pseudo-plus btn-reset"
      type="button"
      :disabled="isDisabledZoomIn"
      @click="zoomIn"
    />
  </div>
</template>

<script>
export default {
  name: 'DocumentZoom',
  props: {
    scale: {
      type: Number,
      required: true
    },
    increment: {
      type: Number,
      default: 0.5
    }
  },

  data: () => ({
    isZoom: false
  }),

  computed: {
    buttonLoupeClasses () {
      return (this.isZoom) ? 'pseudo-minus' : 'pseudo-plus'
    },

    isDisabledZoomIn () {
      return this.scale >= 4.5
    },

    isDisabledZoomOut () {
      return this.scale <= 1
    }
  },

  watch: {
    isDisabledZoomIn (bool) {
      if (!bool) return

      this.setIsZoom(true)
    },

    isDisabledZoomOut (bool) {
      if (!bool) return

      this.setIsZoom(false)
    }
  },

  methods: {
    zoomIn () {
      this.updateScale(this.scale + this.increment)
    },

    zoomOut () {
      this.updateScale(this.scale - this.increment)
    },

    updateScale (scale) {
      this.$emit('update-scale', { scale })
    },

    switchZoom () {
      if (this.isZoom) {
        this.updateScale(2)
      } else {
        this.updateScale(4.5)
      }

      this.toggleIsZoom()
    },

    updateFit (fit) {
      this.$emit('update-fit', fit)
    },

    toggleIsZoom () {
      this.isZoom = !this.isZoom
    },

    setIsZoom (bool) {
      this.isZoom = bool
    }
  }
}
</script>

<style lang="scss">
.document-zoom {
  &__button {
    border-radius: 3px;
    min-width: 30px;
    min-height: 30px;
    padding: 5px 10px;
    color: white;

    &::before,
    &::after {
      width: 2px;
      height: 40%;
    }

    &:focus-visible,
    &:hover {
      background-color: transparentize(white, .9);
    }
  }

  &__button-loupe {
    &::before,
    &::after {
      height: 26%;
      top: 47%;
      left: 46%;
    }

    path {
      fill: currentColor;
    }
  }
}
</style>
