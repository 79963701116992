<template>
  <div class="image-preview">
    <img class="image-preview__image" :src="fileURL" :alt="fileName">
    <slot />
  </div>
</template>

<script>
import { mixinFile } from '@/mixins/files/mixinFile'

export default {
  name: 'ImagePreview',
  mixins: [mixinFile]
}
</script>
