import { getURL, getFileName } from '@/utils/files'

export const mixinFile = {
  props: {
    file: {
      type: [File, Object],
      required: true
    }
  },

  computed: {
    fileName () {
      return getFileName(this.file)
    },
    fileURL () {
      return getURL(this.file)
    }
  }
}
