<template>
  <div class="scrolling-page">
    <slot v-bind="{ isPageFocused, isElementFocused }" />
  </div>
</template>
<script>
export default {
  name: 'ScrollingPage',
  props: {
    page: {
      type: Object,
      required: true
    },
    focusedPage: {
      type: Number,
      default: null
    },
    scrollTop: {
      type: Number,
      default: 0
    },
    clientHeight: {
      type: Number,
      default: 0
    },
    enablePageJump: {
      type: Boolean,
      default: false
    }
  },

  data: () => ({
    elementTop: 0,
    elementHeight: 0
  }),

  computed: {
    isPageFocused () {
      return this.page.pageNumber === this.focusedPage
    },

    isElementFocused () {
      if (!this.elementHeight) return

      const halfHeight = (this.elementHeight / 2)
      const halfScreen = (this.clientHeight / 2)
      const delta = this.elementHeight >= halfScreen ? halfScreen : halfHeight
      const threshold = this.scrollTop + delta

      return this.elementTop < threshold && this.bottom >= threshold
    },

    bottom () {
      return this.elementTop + this.elementHeight
    }
  },

  watch: {
    scrollTop: 'updateElementBounds',
    clientHeight: 'updateElementBounds',
    isPageFocused: 'jumpToPage'
  },

  created () {
    this.$on('update-visibility', this.updateElementBounds)
  },

  mounted () {
    this.updateElementBounds()
  },

  methods: {
    jumpToPage () {
      if (!this.enablePageJump || this.isElementFocused || !this.isPageFocused) return

      this.$emit('page-jump', this.elementTop)
    },

    updateElementBounds () {
      const { offsetTop, offsetHeight } = this.$el
      this.elementTop = offsetTop
      this.elementHeight = offsetHeight
    }
  }
}
</script>
